export default {
  organizations: [],
  isInitialized: false,
  error: null,
  isLoading: false,
  userOrganization: {
    orgLogoLink: null,
  },
  selectedOrganization: null,
};
