export const getStreams = (state) => state.streams;

export const getActiveStreamMarkers = (state) => {
  const markers = [];

  state.displayedVideos.forEach((vid) => {
    const stream = state.streams.find((s) => s.id === vid.id);
    if (stream.position) {
      markers.push({ position: { lat: stream.position.latitude, lng: stream.position.longitude } });
    }
  });

  return markers;
};
