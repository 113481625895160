import firebase from 'firebase/app';
import 'firebase/storage';

export const SET_STATE = (state, { key, value }) => {
  state[key] = value;
};

export const INITIALIZE = (state) => {
  state.isInitialized = true;
};

export const RESET_STATE = (state) => {
  state.organizations = [];
  state.isInitialized = false;
  state.error = null;
  state.isLoading = false;
  state.userOrganization = {};
};

export const SET_ORG_SELECTED = async (state, { organization, isInit }) => {
  try {
    // if init (upon login set logo directly) or user did not select any organization from the dropdown
    if (isInit) {
      state.selectedOrganization = organization;
      state.userOrganization = organization;
      state.userOrganization.orgLogoLink = organization.orgLogoLink;
    } else {
      // else condition for user who uses the dropdown to select different organization
      // get organization(orgId) from local storage (set during dropdown selection)
      state.selectedOrganization = localStorage.getItem('organization');
      // filter out the selected organization then set the orgLogo
      state.userOrganization = state.organizations.find((e) => e.id === state.selectedOrganization);
      const filePath = decodeURIComponent(state.userOrganization.orgLogoLink);
      const url = await firebase.storage().refFromURL(filePath).getDownloadURL();
      state.userOrganization.orgLogoLink = url;
    }
  } catch (error) {
    state.userOrganization.orgLogoLink = null;
  }
};

export const SET_USER_ORGANIZATION = async (state, { organization, isInit }) => {
  await SET_ORG_SELECTED(state, { organization, isInit });
};
