export default {
  resolved: false,
  requested: false,
  providerLoginInfos: {},
  username: null,
  userId: null,
  rooms: [],
  claims: '',
  role: '',
};
