import firebase from 'firebase/app';
import 'firebase/firestore';

export const getUsers = async ({ state, commit }) => {
  const db = firebase.firestore();
  commit('SET_STATE', { key: 'isLoading', value: true });
  let query;
  const { currentUser } = firebase.auth();
  const { claims } = await currentUser.getIdTokenResult();
  const snapshotUser = await db.doc(`users/${claims.user_id}`).get();
  const userData = snapshotUser.data();
  if (claims?.superAdmin) {
    query = db.collection('users').where('role', 'in', ['superAdmin', 'eyeStaff', 'orgAdmin', 'orgUser']);
  } else if (claims?.eyeStaff) {
    query = db.collection('users').where('role', 'in', ['orgAdmin', 'orgUser', 'eyeStaff']);
  } else if (claims?.orgAdmin) {
    query = db.collection('users').where('role', 'in', ['orgAdmin', 'orgUser', 'eyeStaff'])
      .where('organization', '==', userData.organization);
  } else {
    query = db.collection('users').where('role', 'in', ['orgAdmin', 'orgUser'])
      .where('organization', '==', userData.organization);
  }
  query.onSnapshot((snapshot) => {
    const users = [...state.users];

    const changes = snapshot.docChanges();
    changes.forEach((c) => {
      if (c.type === 'added') {
        const organization = c.doc.data().organization.id;

        users.push({ ...c.doc.data(), id: c.doc.id, organization });
      } else if (c.type === 'modified') {
        const userIndex = users.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          const organization = c.doc.data().organization.id;

          users[userIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
            organization,
          };
        }
      } else if (c.type === 'removed') {
        const userIndex = users.findIndex((s) => s.id === c.doc.id);

        if (userIndex !== -1) {
          users.splice(userIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'users', value: users });
    commit('SET_STATE', { key: 'isLoading', value: false });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};
