<template>
  <v-app>
    <Navbar v-if="showNav" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/UI/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  computed: {
    user() {
      return this.$store.state.auth.providerLoginInfos;
    },
    showNav() {
      return this.$route.name !== 'Login';
    },
  },
};
</script>

<style lang="scss">
@import './styles/app.scss';
</style>
