export const SET_STATE = (state, { key, value }) => {
  state[key] = value;
};

export const TOGGLE_VIDEO = (state, video) => {
  const isVideoRunning = state.displayedVideos.findIndex((vid) => vid.id === video.id);

  if (isVideoRunning !== -1) {
    state.displayedVideos.splice(isVideoRunning, 1);
    state.streams.find((vi) => vi.id === video.id).isLive = false;
  } else if (isVideoRunning === -1) {
    state.displayedVideos.push(video);
    state.streams.find((vi) => vi.id === video.id).isLive = true;
  }
};

export const INITIALIZE = (state) => {
  state.isInitialized = true;
};

export const RESET_STATE = (state) => {
  state.streams = [];
  state.displayedVideos = [];
  state.recordedVideos = [];
  state.isRecordedVideosFetched = false;
  state.isInitialized = false;
};

export const SET_PAGE = (state, page) => {
  state.currentPage = page;
};

export const RESET_NEW_STREAM = (state, value) => {
  state.isNewStream = value;
};

export const SET_PAGES_VALUE = (state, value) => {
  state.pageSize = value;
};
