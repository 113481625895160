import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMask from 'v-mask';
import VueI18n from 'vue-i18n';
import { languages, defaultLocale } from './i18n/index';
import App from './App.vue';
import boots from './boots';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_API_KEY,
  },
});

Vue.config.productionTip = false;
Vue.use(VueMask);

boots({ store, router });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
