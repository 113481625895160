<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">
          {{ $t(dialogTitle) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        {{ $t(dialogSubtitle) }}
      </v-card-text>
      <v-card-text>
        <v-select
          id="lang"
          v-model="language"
          :items="languages"
          label="Langue / Language"
          hide-details
          outlined
          dense
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="agree" :loading="loading" :disabled="loading">
          {{ $t(submitBtnLabel) }}
        </v-btn>
        <v-btn color="grey" text @click="cancel" :disabled="loading">
          {{ $t('labels.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200,
    },
    languages: [
      { value: 'fr', text: 'Français' },
      { value: 'en', text: 'English' },
      { value: 'es', text: 'Español' },
      { value: 'pt', text: 'Português' },
    ],
    language: 'en', // default language
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: 'labels.download_cert',
    },
    dialogSubtitle: {
      type: String,
      default: 'labels.select_lang',
    },
    submitBtnLabel: {
      type: String,
      default: 'labels.download',
    },
  },
  methods: {
    agree() {
      this.$emit('confirm', this.language);
    },
    cancel() {
      this.$emit('close', this.language);
    },
  },
  mounted() {
    this.language = localStorage.getItem('locale');
  },
};
</script>
