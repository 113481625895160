import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

export const getActiveStreams = async ({ state, commit, rootState }, orgId) => {
  const db = firebase.firestore();
  const { currentUser } = firebase.auth();
  const userInfo = await db.collection('users').doc(currentUser.uid).get();
  let query;
  if (rootState.auth.claims?.superAdmin || rootState.auth.claims?.eyeStaff) {
    query = db.collection('streams').where('organizationId', '==', orgId);
  } else if (rootState.auth.claims?.orgAdmin) {
    const organizationInfo = await userInfo.data().organization.get();
    const organizationId = organizationInfo.id;
    query = db.collection('streams').where('organizationId', '==', organizationId);
  } else {
    query = db.collection('streams').where('userId', '==', currentUser.uid);
  }
  state.streams = [];
  state.displayedVideos = [];
  query.onSnapshot((snapshot) => {
    const streams = [...state.streams];
    const activeStreams = [...state.displayedVideos];

    const changes = snapshot.docChanges();
    changes.forEach(async (c) => {
      let snapShotDevice;
      let deviceData;
      if (c.doc.data().streamType === 'WebRTC') {
        const userRef = db.collection('users').doc(c.doc.data().userId);
        snapShotDevice = await db.collection('devices').where('owner', '==', userRef).get();
        deviceData = snapShotDevice.docs[0].data();
      } else {
        snapShotDevice = await db.collection('devices').doc(c.doc.id).get();
        deviceData = snapShotDevice.data();
      }
      if (c.type === 'added' && c.doc.data().recordingType === 'supervisor-call') {
        if (rootState.userPreferences.autoPlay) {
          activeStreams.push({
            ...c.doc.data(),
            isLive: true,
            id: c.doc.id,
            position: deviceData ? deviceData.position : '',
          });
        }

        state.isNewStream = true;

        streams.push({
          ...c.doc.data(),
          isLive: rootState.userPreferences.autoPlay,
          id: c.doc.id,
          position: deviceData ? deviceData.position : '',
        });
      } else if (c.type === 'modified') {
        const streamIndex = streams.findIndex((s) => s.id === c.doc.id);

        if (streamIndex !== -1) {
          streams[streamIndex] = {
            ...c.doc.data(),
            isLive: streams[streamIndex].isLive,
            id: c.doc.id,
          };
        }
      } else if (c.type === 'removed') {
        const streamIndex = streams.findIndex((s) => s.id === c.doc.id);
        const activeStreamIndex = activeStreams.findIndex((s) => s.id === c.doc.id);

        if (activeStreamIndex !== -1) {
          activeStreams.splice(activeStreamIndex, 1);
        }

        if (streamIndex !== -1) {
          streams.splice(streamIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'streams', value: streams });
    commit('SET_STATE', { key: 'displayedVideos', value: activeStreams });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const getVideos = ({ state, commit }, { query, paged }) => {
  state.recordedVideos = [];
  state.unsubVideos = query.onSnapshot((snapshot) => {
    const videos = [...state.recordedVideos];

    const changes = snapshot.docChanges();
    changes.forEach(async (c) => {
      if (c.type === 'added') {
        const vid = c.doc.data();
        const video = { ...vid, id: c.doc.id, ownerName: vid.title };
        videos.splice(c.newIndex, 0, video);
      } else if (c.type === 'modified') {
        const videoIndex = videos.findIndex((s) => s.id === c.doc.id);

        if (videoIndex !== -1) {
          const vid = c.doc.data();
          videos[videoIndex] = {
            ...vid,
            id: c.doc.id,
            ownerName: vid.title,
          };
        }
      } else if (c.type === 'removed') {
        const streamIndex = videos.findIndex((s) => s.id === c.doc.id);

        if (streamIndex !== -1) {
          videos.splice(streamIndex, 1);
        }
      }
    });
    commit('SET_STATE', { key: 'recordedVideos', value: videos });

    if (paged && snapshot.docs.length > 0) {
      commit('SET_STATE', { key: 'firstVisibleVideo', value: snapshot.docs[0] });
      commit('SET_STATE', { key: 'lastVisibleVideo', value: snapshot.docs[snapshot.docs.length - 1] });
    }

    if (!state.isRecordedVideosFetched) {
      commit('SET_STATE', { key: 'isRecordedVideosFetched', value: true });
    }
  });
};

export const getVideosByOrg = async ({ state, dispatch },
  {
    status, orgId, limit = 10, filter,
  }) => {
  const db = firebase.firestore();
  let query = filter === 'all'
    ? db.collection('videos')
      .where('organizationId', '==', orgId)
      .where('deleted', '==', false)
      .orderBy('dateAdded', 'desc')
    : db.collection('videos')
      .where('organizationId', '==', orgId)
      .where('deleted', '==', false)
      .where('recordingType', '==', filter)
      .orderBy('dateAdded', 'desc');
  if (status === 'next') {
    query = query.startAfter(state.lastVisibleVideo).limit(limit);
  } else if (status === 'prev') {
    query = query.endBefore(state.firstVisibleVideo).limitToLast(limit);
  } else if (status === 'back') {
    query = query.endAt(state.lastVisibleVideo).limitToLast(limit);
  } else {
    state.page = 1;
    query = query.limit(limit);
  }
  dispatch('getVideos', { query, paged: true });
  dispatch('getVideoTotalSize', {
    id: orgId, pageSize: limit, isOrgVideo: true, filter,
  });
};

export const getVideoTotalSize = async ({ commit },
  {
    id, pageSize, isOrgVideo, isFavorite, filter,
  }) => {
  const db = firebase.firestore();
  let query = isOrgVideo
    ? db.collection('videos')
      .where('organizationId', '==', id)
      .where('deleted', '==', false)
    : db.collection('videos')
      .where('userId', '==', id)
      .where('deleted', '==', false);
  if (filter !== 'all') {
    query = query.where('recordingType', '==', filter);
  }
  if (isFavorite) {
    query = query.where('isFavorite', '==', true);
  }
  const snapshot = await query.get();
  const pages = snapshot.size % pageSize === 0 ? (snapshot.size / pageSize) : Math.floor(snapshot.size / pageSize) + 1;
  commit('SET_PAGES_VALUE', pages);
};

export const getVideosByUser = async ({ state, dispatch }, {
  id, status, limit, filter = 'all', favorite,
}) => {
  const db = firebase.firestore();
  let query = filter === 'all'
    ? db.collection('videos')
      .where('userId', '==', id)
      .where('deleted', '==', false)
    : db.collection('videos')
      .where('userId', '==', id)
      .where('deleted', '==', false)
      .where('recordingType', '==', filter);
  if (favorite) {
    query = query.where('isFavorite', '==', true);
  }
  query = query.orderBy('dateAdded', 'desc');
  if (status === 'next') {
    query = query.startAfter(state.lastVisibleVideo).limit(limit);
  } else if (status === 'prev') {
    query = query.endBefore(state.firstVisibleVideo).limitToLast(limit);
  } else if (status === 'back') {
    query = query.endAt(state.lastVisibleVideo).limitToLast(limit);
  } else {
    state.page = 1;
    query = query.limit(limit);
  }
  dispatch('getVideos', { query, paged: true });
  dispatch('getVideoTotalSize',
    {
      id, pageSize: limit, isOrgVideo: false, isFavorite: favorite, filter,
    });
};

export const deleteVideo = async ({ commit }, { payload }) => {
  const db = firebase.firestore();
  commit('SET_STATE', { key: 'isLoading', value: true });

  try {
    await db.collection('videos').doc(payload.id).update({
      deleted: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export const generatePdf = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });
  let resp = false;
  try {
    const generateFile = firebase.functions().httpsCallable('generatePdf');
    const result = await generateFile({
      vidId: payload.id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: payload.lang,
    });
    resp = result.data;
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
  return resp;
};

export const getTwilioAccessToken = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });
  try {
    const getAccessToken = firebase.functions().httpsCallable('getTwilioAccessToken');
    const result = await getAccessToken(payload);
    commit('SET_STATE', { key: 'accessToken', value: result.data });
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
};

export const getVideoLink = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });
  let resp = false;
  try {
    const getLink = firebase.functions().httpsCallable('getVideoLink');
    const result = await getLink(payload.id);
    resp = result.data;
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
  return resp;
};

export const getThumbnailLink = async ({ commit }, { payload }) => {
  commit('SET_STATE', { key: 'isLoading', value: true });
  let filePath = payload.thumbnailLink;
  const storageRef = firebase.storage().refFromURL(filePath);
  try {
    filePath = await storageRef.getDownloadURL();
  } catch (error) {
    commit('SET_STATE', { key: 'error', value: error.message });
  } finally {
    commit('SET_STATE', { key: 'isLoading', value: false });
  }
  return filePath;
};
