import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export async function request({ state, commit }) {
  if (state.requested) return;
  commit('SET_STATE', { key: 'requested', value: true });

  firebase.auth().onAuthStateChanged(async (response) => {
    let claimResp;
    let user;
    let organization;
    if (response) {
      claimResp = await firebase.auth().currentUser.getIdTokenResult();
      user = await (await firebase.firestore().collection('users').doc(response.uid).get()).data();
      organization = await (await user.organization.get()).data();
      // get organization id from localstorage if set
      // this means that user selected an organization previously
      const localOrgdata = localStorage.getItem('organization');
      // is init is to check if user slected an organization using the dropdown
      commit('organizations/SET_USER_ORGANIZATION', { organization, isInit: localOrgdata === null }, { root: true });
    }
    commit('SET_STATE', { key: 'resolved', value: true });
    commit('SET_STATE', { key: 'username', value: response?.email });
    commit('SET_STATE', { key: 'userId', value: response?.uid });
    commit('SET_STATE', { key: 'claims', value: claimResp?.claims });
    commit('SET_LOGIN_PROVIDER_INFOS', response);
    commit('SET_ROLE', claimResp?.claims);
  });
}

export function setLoginProviderInfos({ commit }, object) {
  commit('SET_LOGIN_PROVIDER_INFOS', object);
}
