import firebase from 'firebase/app';
import 'firebase/firestore';

export const getOrganizations = ({ state, commit }) => {
  const db = firebase.firestore();

  commit('SET_STATE', { key: 'isLoading', value: true });

  db.collection('organizations').onSnapshot((snapshot) => {
    const organizations = [...state.organizations];

    const changes = snapshot.docChanges();
    changes.forEach((c) => {
      if (c.type === 'added') {
        organizations.push({ ...c.doc.data(), id: c.doc.id });
      } else if (c.type === 'modified') {
        const organizationIndex = organizations.findIndex((s) => s.id === c.doc.id);

        if (organizationIndex !== -1) {
          organizations[organizationIndex] = {
            ...c.doc.data(),
            id: c.doc.id,
          };
        }
      } else if (c.type === 'removed') {
        const organizationIndex = organizations.findIndex((s) => s.id === c.doc.id);

        if (organizationIndex !== -1) {
          organizations.splice(organizationIndex, 1);
        }
      }
    });

    commit('SET_STATE', { key: 'organizations', value: organizations });
    commit('SET_STATE', { key: 'isLoading', value: false });

    if (!state.isInitialized) commit('INITIALIZE');
  });
};

export const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export const setSelectedOrganization = ({ commit }) => {
  // organization data to get from localstorage
  commit('SET_ORG_SELECTED', { organization: '', isInit: false });
};
