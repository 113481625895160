<template>
  <v-app-bar elevation="1" outlined app class="z-increase" height="75">
    <!-- Logo -->
    <v-toolbar-items>
      <div class="d-flex align-center mr-2">
        <span class="hidden-lg-and-up mr-2">
          <!-- Menu for left navbar items md below -->
          <v-menu left bottom :offset-y="true">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>{{ menuIcon }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in links"
                :key="item.id + item.text"
                :to="item.to"
                :href="item.href"
                :target="item.href ? '_blank' : ''"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon v-if="item.href">
                  <v-icon>{{ externalLinkIcon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <span v-if="userOrganization.orgLogoLink" class="hidden-sm-and-down">
          <!-- ORG LOGO -->
          <v-img v-bind:src="userOrganization.orgLogoLink"
            height="60"
            max-width="150"
            contain
            :style="{ 'background-color': isDarkmodeEnabled ? 'white' : '' }" />
        </span>
        <span v-else class="hidden-sm-and-down">
          <!-- EYE LOGO - default when no logo -->
          <v-img src="../../assets/eye_logo.png"
            max-height="60"
            max-width="150"
            contain
            :style="{ 'background-color': isDarkmodeEnabled ? 'white' : '' }"/>
        </span>
      </div>
    </v-toolbar-items>

    <v-toolbar-items class="hidden-md-and-down">
      <!-- Left toolbar items -->
      <v-btn text class="toolbar-nav-btn" v-for="item in links" :key="item.text" :to="item.to" :href="item.href" :target="item.href ? '_blank' : ''">
        <v-icon v-if="item.icon" left dark>{{ item.icon }}</v-icon>
        {{ $t(item.text) }}
        <v-icon v-if="item.href" right light>{{ externalLinkIcon }}</v-icon>
      </v-btn>
    </v-toolbar-items>
    <v-spacer class="hidden-sm-and-down" />

    <v-toolbar-items>
      <!-- Select organization -->
      <div class="d-flex align-center">
        <v-select
          :class="`org-select ${ selectWidth }`"
          v-if="isSuperAdminOrEyeStaff"
          v-model="organization"
          :items="organizations"
          item-text="name"
          item-value="id"
          :label="$t('labels.organization')"
          variant="solo-filled"
          hide-details
          dense
          solo
          @change="onSelectOrg"
        />
      </div>
    </v-toolbar-items>
    <v-spacer />

    <v-toolbar-items>
      <!-- Right toolbar items -->
      <div class="d-flex align-center mr-5">
        <DigitalClockVue color="#2196f3f0" showSeconds class="clock" />
      </div>
      <div class="hidden-sm-and-down d-md-flex align-center">
        <span>{{ username }}</span>
      </div>
      <v-menu left bottom :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>{{ menuIcon }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="hidden-md-and-up">
            <v-list-item-icon>
              <v-icon color="info">{{ userIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span>{{ username }}</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in listItems" :key="item.id" @click="onButtonClick(item.id)">
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <SelectLangDialog
      dialogTitle="labels.switch_lang"
      dialogSubtitle="labels.select_locale"
      submitBtnLabel="labels.confirm"
      :dialog="isSelectLangOpen"
      :loading="false"
      @close="isSelectLangOpen = false"
      @confirm="lang => switchLanguage(lang)" />
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth';
import DigitalClockVue from 'digital-clock-vue';
import {
  mdiLogoutVariant,
  mdiMenu,
  // mdiPlayCircleOutline,
  // mdiStopCircleOutline,
  mdiAccountOutline,
  mdiVideoOutline,
  mdiMovieOutline,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiFileDocument,
  mdiOpenInNew,
  mdiTranslate,
} from '@mdi/js';
import SelectLangDialog from './SelectLangDialog';

export default {
  components: {
    DigitalClockVue,
    SelectLangDialog,
  },
  name: 'NavBar',
  data: () => ({
    isSelectLangOpen: false,
    menuIcon: mdiMenu,
    externalLinkIcon: mdiOpenInNew,
    userIcon: mdiAccountOutline,
    links: [
      { text: 'labels.live', to: '/', icon: mdiVideoOutline },
      { text: 'labels.videos', to: '/videos', icon: mdiMovieOutline },
      {
        text: 'labels.procedures',
        href: './procedures-rev2.pdf',
        icon: mdiFileDocument,
      },
      {
        text: 'labels.112_video',
        href: 'https://112.video/',
        icon: mdiVideoOutline,
      },
    ],
    organization: null,
    windowWidth: null,
  }),
  methods: {
    ...mapActions('organizations', ['setSelectedOrganization']),
    onButtonClick(id) {
      if (id === 'autoPlay') {
        this.$store.commit('userPreferences/TOGGLE_AUTOPLAY');
      } else if (id === 'locale') {
        this.isSelectLangOpen = true;
      } else if (id === 'darkmode') {
        this.$store.commit('userPreferences/TOGGLE_DARKMODE');
        this.$vuetify.theme.dark = this.isDarkmodeEnabled;
      } else if (id === 'logout') {
        firebase
          .auth()
          .signOut()
          .then(
            () => {
              document.location = '/';
              localStorage.removeItem('organization');
            },
            () => {
              // console.error(err);
            },
          );
      } else if (id === 'resources') {
        window.open('https://storage.googleapis.com/eyenation-prod.appspot.com/pdf/public/ressources-bmt-fr.pdf', '_blank');
      }
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      this.isSelectLangOpen = false;
      localStorage.setItem('locale', lang);
    },
    onSelectOrg() {
      // to retain selected organization even user refresh the screen
      localStorage.setItem('organization', this.organization);
      this.setSelectedOrganization();
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      isAutoPlayEnabled: (state) => state.userPreferences.autoPlay,
      isDarkmodeEnabled: (state) => state.userPreferences.darkmode,
      userOrganization: (state) => state.organizations.userOrganization || {},
    }),
    listItems() {
      return [
        // {
        //   icon: mdiAccountOutline,
        //   label: this.username,
        //   color: 'info',
        //   id: 'user',
        // },
        {
          icon: this.isDarkmodeEnabled ? mdiWeatherNight : mdiWeatherSunny,
          label: this.isDarkmodeEnabled ? 'labels.disable_night_mode' : 'labels.activate_night_mode',
          color: 'info',
          id: 'darkmode',
        },
        {
          icon: mdiTranslate,
          label: 'labels.language',
          color: 'info',
          id: 'locale',
        },
        // {
        //   icon: this.ressourcesIcon,
        //   label: 'labels.resources',
        //   color: 'info',
        //   id: 'resources',
        // },
        {
          icon: mdiLogoutVariant,
          label: 'labels.logout',
          color: 'warning',
          id: 'logout',
        },
      ];
    },
    selectWidth() {
      if (this.$vuetify.breakpoint.lg) {
        if (this.windowWidth < 1400) {
          return 'max-200';
        }
        if (this.windowWidth < 1500) {
          return 'max-300';
        }
      }
      return 'max-400';
    },
    organizations() {
      return this.$store.state.organizations.organizations;
    },
    isSuperAdminOrEyeStaff() {
      return this.$store.state.auth.claims?.superAdmin || this.$store.state.auth.claims?.eyeStaff;
    },
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem('locale');
    if (!this.$store.state.organizations.isInitialized) {
      this.$store.dispatch('organizations/getOrganizations');
    }
    const orgId = localStorage.getItem('organization') ?? null;
    if (orgId) this.organization = orgId;
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
};
</script>

<style lang="scss" scoped>
.z-increase {
  z-index: 210 !important;
}
.clock {
  width: 150px;
  height: 35px;
}
.org-select {
  padding: 0 10px;
  &.max-200 {
    max-width: 200px;
  }
  &.max-300 {
    max-width: 300px;
  }
  &.max-400 {
    max-width: 400px;
  }
}
</style>
