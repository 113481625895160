export default {
  streams: [],
  isNewStream: false,
  displayedVideos: [],
  recordedVideos: [],
  isRecordedVideosFetched: false,
  currentPage: 1,
  firstVisibleVideo: null,
  lastVisibleVideo: null,
  isInitialized: false,
  accessToken: false,
  unsubVideos: null,
  pageSize: 0,
};
